@font-face {
	font-family: 'Inter';
	font-weight: 300 700;
  font-display: swap;
  src: url(./assets/fonts/inter/Inter.var.woff2) format('woff2 supports variations'), 
       url(./assets/fonts/inter/Inter.var.woff2) format('woff2-variations'),
       url(./assets/fonts/inter/Inter.var.woff2) format('woff2');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(./assets/fonts/roboto/roboto-latin-300-normal.woff2) format('woff2');
}

@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(./assets/fonts/roboto/roboto-latin-300-italic.woff2) format('woff2');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(./assets/fonts/roboto/roboto-latin-400-normal.woff2) format('woff2');
}

@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(./assets/fonts/roboto/roboto-latin-400-italic.woff2) format('woff2');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(./assets/fonts/roboto/roboto-latin-500-normal.woff2) format('woff2');
}

@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(./assets/fonts/roboto/roboto-latin-500-italic.woff2) format('woff2');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(./assets/fonts/roboto/roboto-latin-700-normal.woff2) format('woff2');
}

@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(./assets/fonts/roboto/roboto-latin-700-italic.woff2) format('woff2');
}
